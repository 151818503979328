import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Link, Switch, Route } from 'react-router-dom';

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import "./assets/base.scss";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import ForgotPassword from "./Pages/Components/Login/ForgotPassword";
import Login from "./Pages/Components/Login/Login";
import DefaultLoginPage from "./Pages/Components/Login/DefaultLoginPage";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>
    ,

    rootElement
  );
};

renderApp(Main);

if (module.hot) {

  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
// ReactDOM.render(
//   <Provider store={store}>
//   <DefaultLoginPage />
  
//   </Provider>, document.getElementById('root'));