import { common } from "./Shared";
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          toast.error("Server Error..")
            // if ([401, 403].indexOf(response.status) !== -1) {
            //    toast.error("Server Error..")
            // }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
