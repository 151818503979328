import React, { useState, useEffect } from "react";
import { common } from "../../Helpers/Shared";


const useForm = (initialFieldValues, validate) => {
  const [values, setValues] = useState(initialFieldValues)
  const [errors, setErrors] = useState({})
  const [customers, setCustomers] = useState([])
  const [ownerCategories, setOwnerCategories] = useState([])
  const [lightSources, setLightSources] = useState([])
  const [fabricCompositions, setFabricCompositions] = useState([])
  const [washCareCodes, setWashCareCodes] = useState([])
  const [fabricTypes, setfabricTypes] = useState([]);
  const [activeRetailers, setActiveRetailers] = useState([]);
  const [items, setActiveItems] = useState([]);

  const handleInputChange = e => {
    const { name, value } = e.target
    const fieldValue = { [name]: value }
    setValues({
      ...values,
      ...fieldValue
    })
    validate(fieldValue)
  }
  const getRemoteDate = (type, url) => {
    common.sendGetRequest(null, url)
      .then(res => getRemoteDateSuccess(res.data, type),
        res => common.handleError(res))
  }

  const getRemoteDateSuccess = (result, type) => {

    if (result.length > 0) {

      switch (type) {
        case 'Customers':
          var activeCustomers = common.commonPropertyForDropDownns(result, 'CustomerId', 'CustomerName', true);
          setCustomers(activeCustomers);
          break;
        case 'OwnerCategory':
          var activeOwnerCategories = common.commonPropertyForDropDownns(result, 'OwnerCategoryId', 'OwnerCategoryName', true);
          setOwnerCategories(activeOwnerCategories);
          break;
        case 'OwnerCategory':
          var activeOwnerCategories = common.commonPropertyForDropDownns(result, 'OwnerCategoryId', 'OwnerCategoryName', true);
          setOwnerCategories(activeOwnerCategories);
          break;
        case 'FabricComposition':
          var activeFabricCompositions = common.commonPropertyForDropDownns(result, 'FabricCompositionId', 'FabricCompositionName', true);
          
          setFabricCompositions(activeFabricCompositions);
          break;
        case 'WashCareCode':
          var activeWashCareCodes = common.commonPropertyForDropDownns(result, 'WashCareCodeId', 'WashCareCodeName', true);
          setWashCareCodes(activeWashCareCodes);
          break;
        case 'FabricType':
          var activeFabricTypes = common.commonPropertyForDropDownns(result, 'FabricTypeId', 'FabricTypeName', true);
          setfabricTypes(activeFabricTypes);
          break;
        case 'LightSource':
          var activeLightSources = common.commonPropertyForDropDownns(result, 'LightSourceId', 'LightSourceName', true);
          setLightSources(activeLightSources);
          break;
          case 'Retailers':
            // var activeRetailers = common.commonPropertyForDropDownns(result, 'RetailerId', 'RetailerName', true);
            setActiveRetailers(result);
            break;
            case 'Items':
              // var activeRetailers = common.commonPropertyForDropDownns(result, 'RetailerId', 'RetailerName', true);
               var activeLightSources = common.commonPropertyForDropDownns(result, 'StkId', 'StkName', true);
              setActiveItems(activeLightSources);
              break;
        default:
          break;
      }

    }
  }

  const clearData=()=>{
    setValues(initialFieldValues);
    setErrors({});
}

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    clearData,
    getRemoteDate,
    customers,
    fabricCompositions,
    ownerCategories,
    washCareCodes,
    fabricTypes,
    lightSources,
    activeRetailers,
    setActiveRetailers,
    items
  };
}

export default useForm;