export const isAllowed = (right) => {

    if (JSON.parse(localStorage.getItem("currentUser")) != null) {
        return JSON.parse(localStorage.getItem("currentUser")).Permissions.map(x => x).some(v => v == right);
    }
    // return service.currentUser.permissions.map(x => x).some(v => v == right);

}

export const hasRole = (user, roles) =>
    roles.some(role => user.roles.includes(role));