import React, { Fragment } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Link, Switch, Route } from 'react-router-dom';

import cx from "classnames";
import { withRouter } from "react-router-dom";
// import { isUserLogged } from '../../Helpers/IsUserLogged'

import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";
import Login from "../../Pages/Components/Login/Login.js";
import { isLogin } from "../../Helpers/IsUserLogged.js";
import ForgotPassword from "../Components/Login/ForgotPassword";
import DefaultLoginPage from "../Components/Login/DefaultLoginPage";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    return (

      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            {/* <BrowserRouter >
              <Switch>
                <Route path='/#/asas/forgot-password' component={ForgotPassword} ></Route>
              </Switch>
            </BrowserRouter > */}

            <div

              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}>
              {/* <DefaultLoginPage /> */}

              {!isLogin() && <Login />}
              {isLogin() && <AppMain />}
             
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
