import React, { Fragment, Component, useState, useEffect, useRef } from "react";
import { useHistory, Redirect } from 'react-router-dom';
import useForm from '../useForm';
import { BrowserRouter, Link, Switch, Route } from 'react-router-dom';

import Slider from "react-slick";

import bg1 from "../../../assets/utils/images/originals/AFP-ERP-1.jpg";
import bg2 from "../../../assets/utils/images/originals/AFP-ERP-2.jpg";
import bg3 from "../../../assets/utils/images/originals/AFP-ERP-3.jpg";
import avior from "../../../assets/utils/images/originals/AviorsysNew.jpg";

import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { common } from "../../../Helpers/Shared";
import AppMain from "../../../Layout/AppMain";
import ForgotPassword from "./ForgotPassword";
// import GitInfo from 'react-git-info/macro';

const initialFieldValues = {
  UserName: '',
  Password: '',

}
let settings = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

export default function Login() {
  const userNameInput = useRef(null);
  let history = useHistory();
  const [isLoginSuccess, setLoginSuccess] = useState(false);
  const [isForgetPasswordView, setForgetPasswordView] = useState(false);


  useEffect(() => {
    userNameInput.current.focus();
  }, []);

  const handleSubmit = e => {
    e.preventDefault()
    if (validate()) {
      setLoginSuccess(true);
      common.sendPostRequest(values, 'User')
        .then((response) => { signInSuccess(response) })
        .catch((error) => { signInSuccessErrorHandling(error.response) });
    }

  }
  const signInSuccess = (result) => {
    if (result != null) {
      setLoginSuccess(false);
      localStorage.setItem('currentUser', JSON.stringify(result.data));
      history.push('/#/dashboards/crm');
    }
  }
  const signInSuccessErrorHandling = (error) => {
    setLoginSuccess(false);
    if(error!=undefined)
    {
      var errorData = error.data;
      if (error !== null && errorData.userId === -1) {
        var remainingLoginAttempt = 5 - (errorData.AttemptCount);
        localStorage.setItem("remainingLoginAttempt", remainingLoginAttempt);
        let errorMsg = "The username or password you entered is incorrect. You have " + remainingLoginAttempt + " more Login attempts left";
        error.error = errorMsg
        setErrors(error)
        return;
  
      }
      if (errorData.userId == -2 || errorData.userId == -4) {
        // let errorMsg = "You have been blocked from accessing this system. Please contact your system administrator or "+ <a href="www.google.com">Aviorsys support</a> +"to unlock your user account.";
        let errorMsg = <p>You have been blocked from accessing this system. Please contact your system administrator or <a href="https://www.aviorsys.com/contact-us.html" target="_blank"><u>Aviorsys support</u></a> to unlock your user account.</p>
  
        error.error = errorMsg;
        setErrors(error)
        return;
      }
      else{
          var obj={
            error:error.data.Message
          }
          setErrors(obj);
        }
    }
    else{
      var obj={
        error:"Server Error,Contact Administrator"
      }
      setErrors(obj);
    }
    

  }
  const signInAsDifferent = () => {
    history.push('/');
  }
  const validate = (fieldValues = values) => {
    let temp = {}
    if ('UserName' in fieldValues)
      temp.UserName = fieldValues.UserName ? "" : "Username cannot be empty."
    if ('Password' in fieldValues)
      temp.Password = fieldValues.Password ? "" : "Password cannot be empty."

    setErrors({
      ...temp
    })

    if (fieldValues == values)
      return Object.values(temp).every(x => x == "")
  }
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues, validate)

  const showValidateMsg = (field) => {
    if (errors[field]) {
      return <small className="text-danger" style={{ color: 'red' }}>{errors[field]}</small>
    }
  }
  const forgotPassword = () => {
    history.push('/reset-password');
  }
  const redirect = () => {
    setForgetPasswordView(true);
    history.push('/forget-password');

  }

  // const gitInfo = GitInfo();

  return (
    <Fragment>
    
     { !isForgetPasswordView  && <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }} />
                  <div className="slider-content">
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }} />
                  <div className="slider-content">
                   
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }} />
                  <div className="slider-content">
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              {/* <div className="app-logo" /> */}
              <img src={avior} style={{ width: 400 }} />
              {/* <h4 className="mb-0">
                <div>Welcome !!</div>
                {/* <span>Please sign in to your account.</span> 
              </h4> */}
              {/* <h6 className="mt-3">
                  No account?{" "}
                  <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="text-primary">
                    Sign up now
                  </a>
                </h6> */}
              <Row className="divider" />
              <div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Username</Label>
                        <Input type="text" name="UserName" id="exampleEmail" placeholder="Username here..."
                          name="UserName"
                          value={values.UserName}
                          onChange={handleInputChange}
                          ref={userNameInput}
                          maxLength="50" />
                        {showValidateMsg('UserName')}

                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="examplePassword">Password</Label>
                        <Input type="password" name="Password" id="examplePassword" placeholder="Password here..."
                          name="Password"
                          value={values.Password}
                          onChange={handleInputChange}
                        />

                        {showValidateMsg('Password')}

                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup check>
                    {/* <Input type="checkbox" name="check" id="exampleCheck" /> */}
                    {/* <Label for="exampleCheck" check>
                      Keep me logged in
                      </Label> */}
                  </FormGroup>
                  <Row className="divider" />
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      {/* <a onClick={redirect} className="btn-lg btn btn-link" >
                        Recover Password
                        </a>{" "} */}

                      <Button color="primary" size="lg" type="submit" disabled={isLoginSuccess}>
                        Login
                        </Button>
                    </div>
                  </div>
                  {errors.error && <span className="text-danger">{errors.error}</span>}
                  {isLoginSuccess && <p>Authenticating... Please wait!</p>}
                  {/* <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        <Label for="exampleEmail">Branch: {gitInfo.branch}</Label>
                        <Label for="exampleEmail">Date: {gitInfo.commit.date}</Label>
                        <Label for="exampleEmail">Message: {gitInfo.commit.message}</Label>
                        <Label for="exampleEmail">Version: {gitInfo.tags}</Label>
                    </div>
                  </div> */}
                </form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
      }
      {/* {isForgetPasswordView && <ForgotPassword/>} */}
    </Fragment>
  )
}
