import axios from "axios";
import { authHeader } from "./Auth-Header";
import { handleResponse } from "./Handle-Response";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const currentUserSubject = JSON.parse(localStorage.getItem('currentUser'));

var url = '';


if (process.env.NODE_ENV === 'development') {
     //url = 'https://localhost:44366/api/';
    //url = 'http://52.70.234.125:5050/api/';
    //url ='https://api-afperp.aviorsys.com/api/'
    //url='https://103.1.179.74:84/api/';
//    url= 'http://localhost:5001/api/';
   // url='http://172.16.1.80:44366/api/';
    url= 'https://afperp-api.aviorsys.com/api/';
  //  url = 'https://localhost:44366/api/'
}

if (process.env.NODE_ENV === 'production') {
//  url= 'http://localhost:5001/api/';
  // url='http://172.16.1.80:44366/api/';
    url='https://afperp-api.aviorsys.com/api/';
    //url = 'http://52.70.234.125:5050/api/';
    // url = 'https://localhost:44366/api/';
    //url= 'http://103.1.179.74:84/api/';
    //url= 'https://103.1.179.74:84/api/';
    //url = 'http://172.16.1.80:84/api/';
   // url = 'https://localhost:44366/api/';
}

toast.configure();
export const common = {
  sendPostRequest,
  sendGetRequest,
  logout,
  commonPropertyForDropDownns,
  commonPropertyForDropDownnUnits,
  showNotification,
  handleError,
  createNormalSelectList,
  getDate,
  percentages,
  formatDate,
  setValueToMultiDropDown,
  imageUrl,
  getMonth,
  getYear,
  get currentUser() { return JSON.parse(localStorage.getItem('currentUser')); }
};

function setValueToMultiDropDown(dropdowns, result) {
  console.log('dasu');
  var multiArray = [];
  if (dropdowns != null && dropdowns.length > 0) {
    dropdowns.find(op => {
      if (result != null) {
        for (var i = 0; i < result.length; i++) {
          if (result[i] == op.value) {
            multiArray.push(op)
          }
        }


      }
    })
    return multiArray;
  }
  return multiArray;

}
function imageUrl() {
  return 'https://afperp-api.aviorsys.com/UserFiles'
}
function handleError() {
  // toast.error("Server error.Contact Administrator");
}
function commonPropertyForDropDownns(list, id, name, isSelect) {
  var array = [];
  // array = [{value:null,label:'Select'}];
  if (isSelect) {
    array = [{ value: '', label: 'Select' }];

  }
  if (list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      var obj = {}
      obj.value = list[i][id];
      obj.label = list[i][name];
      array.push(obj)
    }

    return array;
  }
  return array;

}
function showNotification(category) {
  switch (category.Result) {
    case 'Success':
      toast.success(category.Message)
      break;
    case 'Warning':
      toast.warning(category.Message)
      break;
    case 'Error':
      toast.error(category.Message)
      break;
    default:
      break;
  }
}
function logout() {
  localStorage.removeItem('currentUser');
}

function createNormalSelectList(name, val) {
  return { target: { name: name, value: val.value == undefined ? val : val.value } }
}
function percentages() {
  var percentageArray = []
  for (let i = 1; i <= 100; i++) {
    var obj = {};
    obj.Id = i;
    obj.Name = i;
    percentageArray.push(obj);
  }
  return commonPropertyForDropDownns(percentageArray, "Id", "Name");
}

function getDate(date) {
  return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
}



function IsNumeric(input) {
  return (input - 0) == input && ('' + input).trim().length > 0;
}

function getMonth() {

  var months = [];
  months.push({ value: '', label: 'Select' },{ value: 1, label: 'January' }, { value: 2, label: 'February' }, { value: 3, label: 'March' }, { value: 4, label: 'April' }, { value: 5, label: 'May' }, { value: 6, label: 'June' }, { value: 7, label: 'July' }, { value: 8, label: 'August' }, { value: 9, label: 'September' },
    { value: 10, label: 'October' }, { value:11, label: 'November' }, { value: 12, label: 'December' },
  )
  return months;
}


function getYear() {

  var year = [];
  year.push({ value: 2019, label: '2019' },{ value:2021, label: '2021' }, { value: 2022, label: '2022' }, { value: 2023, label: '2023' }, { value: 2024, label: '2024' },{ value: 2024, label: '2025' },{ value: 2024, label: '2026' },{ value: 2024, label: '2027' },{ value: 2024, label: '2028' },{ value: 2024, label: '2029' },{ value: 2024, label: '2030' }
  )
  return year;
}



function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}


function sendPostRequest(obj, myUrl, isFileUpload) {
  return axios.post(url + myUrl, obj, { headers: authHeader(isFileUpload) })
}

function sendGetRequest(obj, myUrl) {
  return axios.get(url + myUrl, { headers: authHeader(), params: obj })
}
function commonPropertyForDropDownnUnits(list, id, name, division) {
  var array = [{ value: '', label: 'Select' }];
  if (list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      var obj = {}
      obj.value = list[i][id];
      obj.label = list[i][name];
      obj.DivisionIdx = list[i][division];
      array.push(obj)
    }

    return array;
  }
  return array;

}
