import React from 'react'

export default function ForgotPassword() {
    return (
        <div>
         
        </div>
    )
}

// content: [
//    ,
//     {
//         label: "Artwork Formate",
//         to: "#/master-file/artwork-format",
//     },
//     {
//         label: "Artwork Type",
//         to: "#/master-file/artwork-type",
//     },
//     {
//         label: "BD Officer",
//         to: "#/master-file/bd-officer",
//     },
//     {
//         label: "Bin",
//         to: "#/master-file/warehousebin",
//     },
//     {
//         label: "Buying Office",
//         to: "#/master-file/buying-office",
//     },

//     {
//         label: "Category",
//         to: "#/master-file/category",
//     },
//     {
//         label: "Certificate",
//         to: "#/master-file/certificate",
//     },
//     {
//         label: "Cluster",
//         to: "#/master-file/cluster",
//     },
//     {
//         label: "Contact Person Type",
//         to: "#/master-file/contact-Person-type",
//     },
//     // {
//     //     label: "Contact Person",
//     //     to: "#/master-file/contact-person",
//     // },
//     {
//         label: "Cost Type",
//         to: "#/master-file/cost-type",
//     },
//     {
//         label: "Customer Category",
//         to: "#/master-file/customer-category",
//     },
//     {
        
//     },
//     {
//         label: "Item",
//         to: "#/master-file/item",
//     },
    
//     {
//         label: "DD Type",
//         to: "#/master-file/dd-type",
//     },
//     {
//         label: "Delivery Type",
//         to: "#/master-file/delivery-type",
//     },
//     {
//         label: "Fabric Status",
//         to: "#/master-file/fabric-status",
//     },

//     {
       
//     },
//     {
       
//     },
//     {
//         label: "Final Appearance",
//         to: "#/master-file/final-appearance",
//     },

//     {
//         label: "Group",
//         to: "#/master-file/group",
//     },
//     {
//         label: "Item Category",
//         to: "#/master-file/item-category",
//     },
//     {
       
//     },
//     {
//         label: "Loading Form",
//         to: "#/master-file/loading-form",
//     },
//     {
//         label: "Loyalty Status",
//         to: "#/master-file/loyalty-status",
//     },
//     {
//         label: "Measuring Unit",
//         to: "#/master-file/measuring-unit",
//     },
//     {
//         label: "Order Type",
//         to: "#/master-file/order-type",
//     },
//     {
      
//     },

//     {
        
//     },

//     {
//         label: "Part",
//         to: "#/master-file/part",
//     },
//     {
//         label: "Patch Type",
//         to: "#/master-file/patch-type",
//     },
//     {
//         label: "Position",
//         to: "#/master-file/position",
//     },
//     {
//         label: "Pre Print Sample",
//         to: "#/master-file/pre-print-sample",
//     },
//     {
//         label: "Print On",
//         to: "#/master-file/print-on",
//     },

//     {
//         label: "Pre Print",
//         to: "#/master-file/pre-print",
//     },
//     {
       
//     },
//     {
//         label: "RM Category",
//         to: "#/master-file/rM-category",
//     },
//     {
//         label: "RM Type",
//         to: "#/master-file/rM-type",
//     },

//     {
//         label: "Stock Type",
//         to: "#/master-file/stocktype",
//     },

//     {
//         label: "Tax Code",
//         to: "#/master-file/tax-code",
//     },
//     {
//         label: "Technique",
//         to: "#/master-file/technique",
//     },

//     {
//         label: "Wash Care Code",
//         to: "#/master-file/wash-care-code",
//     },

//     {
//         label: "Wash Care Status",
//         to: "#/master-file/wash-care-status",
//     },


//     {
//         label: "Warehouse",
//         to: "#/master-file/warehouse",
//     },



// ],
     