import React, { Fragment, Component } from "react";
import { Route, Redirect } from 'react-router'

import AppMain from '../Layout/AppMain';
import Login from '../Pages/Components/Login/Login';
const TOKEN_KEY = 'currentUser';

export const login = () => {
    localStorage.setItem(TOKEN_KEY, 'TestLogin');
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}