import { common } from "./Shared";

export function authHeader(flag) {
    if(flag){
        return { 'Content-Type': 'multipart/form-data' }
    }
    const currentUser = common.currentUser;
    if (currentUser && currentUser.Token) {
        return {  Authorization: `Bearer ${currentUser.Token}` }
    } else {
        return { 'Content-Type': 'application/json' }
    }


}
