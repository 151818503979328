import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { isLogin } from '../../../Helpers/IsUserLogged';
import { isAllowed } from './UserRight'


const PrivateRoute = ({ component: Component, permission, ...rest }) => {
    return (
        <Route {...rest} render={props =>
            (
                isLogin() ?
                    (isAllowed(permission) ? <Component {...props} /> : <Redirect to="/dashboards/crm/Unauthoarized" />)
                    : <Redirect to="/" />
            )} />

    );
   
};

export default PrivateRoute
